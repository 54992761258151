import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../components/input';

import { FIELDS, PRICEFIELDSSHORT } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';

import { VatFields } from './components/VatFields';

import style from '../../../../styles/form.module.scss';

const LABELS = {
  PRICE: 'Цена',
  BASE: 'Стоимость',
  COMMISSION: 'Наценка Smartway',
};

const PriceFields = ({
  isEditing,
  priceDetails,
  validation,
  priceJson,
  onChangePriceInput,
  validationPrice,
  onChangeVat,
}) => {
  const priceBase = isEditing ? priceJson.Base : priceDetails.Base;
  const priceCommission = isEditing ? priceJson.Commission : priceDetails.commission;
  const commissionField = isEditing ? PRICEFIELDSSHORT.COMMISSION : FIELDS.COMMISSION;
  const commissionValidation = isEditing ? validation.Commission : validation.commission;

  const renderVatFields = () => {
    if (isEditing) return null;

    return <VatFields onChangeVat={ onChangeVat } priceDetails={ priceDetails } />;
  };

  return (
    <div className={ `${style.row} ${style.panel}` }>
      <h4>{ LABELS.PRICE }</h4>
      <div className={ style.row }>
        <div className={ style['col-1-3'] }>
          <Input
            field={ FIELDS.BASEPRICE }
            value={ priceBase }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            label={ LABELS.BASE }
            onChange={ onChangePriceInput }
            onBlur={ validationPrice }
            valid={ validation.Base }
          />
        </div>
        <div className={ style['col-1-3'] }>
          <Input
            field={ commissionField }
            value={ priceCommission }
            label={ LABELS.COMMISSION }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ onChangePriceInput }
            onBlur={ validationPrice }
            valid={ commissionValidation }
          />
        </div>
        { renderVatFields() }
      </div>
    </div>
  );
};

PriceFields.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  priceDetails: PropTypes.object,
  validation: PropTypes.object.isRequired,
  priceJson: PropTypes.object,
  onChangePriceInput: PropTypes.func.isRequired,
  validationPrice: PropTypes.func.isRequired,
  onChangeVat: PropTypes.func.isRequired,
};

PriceFields.defaultProps = {
  priceDetails: {},
  priceJson: {},
};

export { PriceFields };
