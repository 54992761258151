import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input';
import { FlatButton } from '../../../../components/button';

import { FIELDS, FUNDSFIELDS, PRICEFIELDSSHORT, FLIGHT_TYPE_FORMAT } from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';

import { findDepartments } from '../../../../bi/utils/trip';
import { validateAnalytics } from '../../../../bi/utils/customAnalytics';
import { isMandatory } from '../../../../bi/utils/account';
import {
  normalizeDateConfirm,
  removeEmployeeSuggest,
  validateSubmitFields,
  decreaseCountEmployees,
  selectAnalytics,
  normalizeFlightConfirm,
  selectEmployee,
  createStateTrip,
  editStateTrip,
  editingStateTrip,
  copyStateTrip,
} from '../../../../bi/utils/vipHall';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import SelectProject from '../selectProject';
import SelectDepartment from '../selectDepartment';
import SelectCompany from '../selectCompany';
import AnalyticsRows from '../analyticsRows';
import EditHistory from '../EditHistory';

import { AirportsFields } from './components/AirportsFields';
import { AdditionalFields } from './components/AdditionalFields';
import { ProviderFields } from './components/ProviderFields';
import { PriceFields } from './components/PriceFields';
import { CancellationFields } from './components/CancellationFields';
import { EmployeeFields } from './components/EmployeeFields';
import { CountEmployeesFields } from './components/CountEmployeesFields';
import { TypesFields } from './components/TypesFields';

import style from '../../styles/form.module.scss';

const LABELS = {
  VOUCHER_INFO: 'Данные ваучера',
  NAME: 'Название услуги',
  ADD_TRIP: 'Добавить заказ',
  CONFIRM: 'Подтвердить изменения',
  TERMINAL: 'Терминал',
  COMMENT: 'Дополнительная информация',
  NUMBER: 'Номер рейса',
};

const SERVICE_NAME = 'vipHall';

export default class VipZalForm extends Component {
  static propTypes = {
    vipHall: PropTypes.object.isRequired,
    companies: PropTypes.array,
    tripService: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    mandatoryProject: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    analytics: PropTypes.array.isRequired,
    autocomplete: PropTypes.func.isRequired,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    addTrip: PropTypes.func,
    guidService: PropTypes.object.isRequired,
    isCopy: PropTypes.bool,
    isEditing: PropTypes.bool,
  };

  static defaultProps = {
    companies: [],
    mandatoryProject: [],
    tripAnalyticsValueIds: [],
    isCopy: false,
    isEditing: false,
    setTripAnalytics: () => {},
    addTrip: () => {},
  };

  constructor(props) {
    super(props);
    const { vipHall } = props;

    this.state = this.setVipHall(vipHall);
  }

  componentDidMount() {
    const { isEditing, tripService } = this.props;

    this.unsubscribe = tripService.subscribe(this.updateTripData);

    if (isEditing) {
      this.setDepartment();
    }

    this.handleProviderFocus();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.vipHall.OrderTripItemId !== this.state.OrderTripItemId) {
      this.state = this.setVipHall(newProps.vipHall);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ employeesList }) => this.setState({ employeesList });

  setDepartment = async () => {
    const { employeesListForPick, CompanyId } = this.state;
    const { tripService: { getEmployeeDataByID } } = this.props;
    const currentEmployee = await getEmployeeDataByID(employeesListForPick[0].Id);

    this.setState({ Departments: findDepartments(currentEmployee, CompanyId) });
  }

  setVipHall = (vipHall) => {
    const { isCopy, isEditing } = this.props;
    const { isEdit, JsonData } = vipHall;
    const jsonData = typeof JsonData === 'string' ? JSON.parse(JsonData) : JsonData;

    if (isEdit) {
      return editStateTrip(jsonData, vipHall);
    }

    if (isEditing) {
      return editingStateTrip(jsonData, vipHall);
    }

    if (isCopy) {
      return copyStateTrip(jsonData, vipHall);
    }

    return createStateTrip(jsonData, vipHall);
  }

  normalizeDate = () => {
    const { JsonData } = this.state;

    const { checkinDate, checkoutDate, cancellationDate, cancellationTime } = normalizeDateConfirm(JsonData);

    JsonData.CheckinDate = checkinDate;
    JsonData.CheckoutDate = checkoutDate;
    JsonData.CheckinTime = 0;
    JsonData.CheckoutTime = 0;
    JsonData.CancellationDate = cancellationDate;
    JsonData.CancellationTime = cancellationTime;
  }

  handleChangeInput = (e, field, value) => {
    const part = field.split('.');

    const JsonData = part.length === 1 ? {
      ...this.state.JsonData,
      [field]: value,
    } : {
      ...this.state.JsonData,
      [part[0]]: {
        ...this.state.JsonData[part[0]],
        [part[1]]: value,
      },
    };

    return this.setState({ JsonData });
  };

  handleChangeSegmentInput = (e, field, value, i) => {
    const { JsonData } = this.state;

    const changeFlight = JsonData.Flight.map((item, index) => {
      if (index === i) {
        const segment = item;
        segment[field] = value;

        return segment;
      }

      return item;
    });

    this.setState({
      JsonData: {
        ...JsonData,
        Flight: changeFlight,
      },
    });
  }

  handleChangeFlightType = (e, field, value) => {
    const { JsonData, validation } = this.state;

    if (value === FLIGHT_TYPE_FORMAT.TRANSIT && !(JsonData.Flight.length > 2)) {
      return this.setState({
        JsonData: {
          ...JsonData,
          Flight: [{
            ...JsonData.Flight[0],
            Type: value,
          },
          {
            DepartureAirport: {
              label: '',
              selected: {},
              suggests: [],
            },
            ArrivalAirport: {
              label: '',
              selected: {},
              suggests: [],
            },
            Terminal: '',
            Number: '',
            CheckinDate: '',
            CheckoutDate: '',
            CheckinTime: '',
            CheckoutTime: '',
            Type: value,
          }],
        },
        validation: {
          ...validation,
          Segments: [validation.Segments[0], {
            DepartureAirport: '',
            ArrivalAirport: '',
            Terminal: '',
            Number: '',
            CheckinDate: '',
            CheckoutDate: '',
            CheckinTime: '',
            CheckoutTime: '',
          }],
        },
      });
    }

    return this.setState({
      JsonData: {
        ...JsonData,
        Flight: [{
          ...JsonData.Flight[0],
          Type: value,
        }],
      },
      validation: {
        ...validation,
        Segments: [validation.Segments[0]],
      },
    });
  }

  handleChangeState = (e, field, value) => this.setState({ [field]: value });

  handleSelectEmployee = async ({ Id }, index) => {
    const { tripService } = this.props;
    const { CompanyId, selectedEmployeesIds, employeesListForPick, Departments } = this.state;

    const { departments, result, updSelectedEmployeesIds, updEmployeesListForPick } = await selectEmployee(tripService, CompanyId, selectedEmployeesIds, employeesListForPick, Departments, index, Id);

    this.setState({
      Departments: departments,
      EmployeeId: Id || 0,
      validation: {
        ...this.state.validation,
        Employees: result,
        Departments: departments,
      },
      selectedEmployeesIds: updSelectedEmployeesIds,
      employeesListForPick: updEmployeesListForPick,
    });
  };

  handleRemoveEmployeeSuggest = (index) => {
    const { employeesListForPick, DepartmentId, CompanyId, selectedEmployeesIds, Departments } = this.state;
    const { isEditing } = this.props;

    const {
      newEmployeesList,
      updSelectedEmployeesIds,
      noEmployees,
      newDepartment,
    } = removeEmployeeSuggest(index, employeesListForPick, selectedEmployeesIds, CompanyId, isEditing, Departments);

    this.setState({
      employeesListForPick: newEmployeesList,
      selectedEmployeesIds: updSelectedEmployeesIds,
      DepartmentId: noEmployees || Object.keys(newEmployeesList[0]).length === 0 ? 0 : DepartmentId,
      Departments: noEmployees ? [] : newDepartment,
    });
  }

  handleIncreaseCountEmployees = (index) => {
    const { employeesListForPick } = this.state;

    if (employeesListForPick.length === 4) {
      return;
    }

    this.setState({ employeesListForPick: [...employeesListForPick, { id: index }] });
  };

  handleDecreaseCountEmployees = () => {
    const { employeesListForPick, selectedEmployeesIds } = this.state;

    const updSelectedEmployeesIds = decreaseCountEmployees(employeesListForPick, selectedEmployeesIds);

    this.setState({
      employeesListForPick: employeesListForPick.slice(0, employeesListForPick.length - 1),
      selectedEmployeesIds: updSelectedEmployeesIds,
    });
  }

  handleSelectSuggestAirport = (field, value, i) => {
    const { JsonData } = this.state;
    const { City, Country, Code, Name } = value;

    const newFlight = JsonData.Flight.map((item, index) => {
      if (index === i) {
        const segment = item;
        segment[field].label = `${Code} ${City} ${Country} ${Name}`;
        segment[field].choosenSuggest = { Code, City, Name };

        return segment;
      }

      return item;
    });

    this.setState({
      JsonData: {
        ...JsonData,
        Flight: newFlight,
      },
    });
  }

  handleAutocompleteAirport = async (i, field) => {
    const { JsonData, validation } = this.state;

    const airports = await this.props.autocomplete(this.state.JsonData.Flight[i][field].label);

    const result = this.props.tripService.vipHallTripFieldValidation(field, this.state.JsonData.Flight[i][field].label);

    const suggestAirport = airports.reduce((airport, newAirport) => {
      if (!airport.ChildLocation) return [...airport, newAirport];

      return [...airport.ChildLocation, ...airport, newAirport];
    }, []);

    const newFlight = JsonData.Flight.map((item, index) => {
      if (index === i) {
        const segment = item;
        segment[field].suggests = suggestAirport;

        return segment;
      }

      return item;
    });

    const newValidation = validation.Segments.map((item, index) => {
      if (index === i) {
        const segment = item;
        segment[field] = result;
      }

      return item;
    });

    this.setState({
      JsonData: {
        ...JsonData,
        Flight: newFlight,
        validation: {
          ...JsonData.validation,
          Segments: newValidation,
        },
      },
    });
  }

  handleChangeSuggestAirport = (field, validField, value, i) => {
    if (value.length > 100) return;

    const { JsonData } = this.state;

    const newFlight = JsonData.Flight.map((item, index) => {
      if (index === i) {
        const segment = item;
        segment[field].label = value;

        return segment;
      }

      return item;
    });

    this.setState({
      JsonData: {
        ...JsonData,
        Flight: newFlight,
      },
    }, () => {
      this.handleAutocompleteAirport(i, field);
    });
  };

  handleChangePriceInput = (e, field, value) => {
    const { isEditing } = this.props;

    if (isEditing) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          PriceDetails: {
            ...this.state.JsonData.PriceDetails,
            [field]: value,
          },
        },
      });
    }

    return this.setState({
      PriceDetails: {
        ...this.state.PriceDetails,
        [field]: value,
      },
    });
  };

  handleConfirm = () => {
    const {
      Description,
      OrderTripItemId,
      CompanyId,
      ServiceType,
      Status,
      PriceDetails,
      JsonData,
      selectedEmployeesIds,
      employeesListForPick,
      ProjectId,
      DepartmentId,
      UserAnalytics = [],
    } = this.state;
    const { addTrip, guidService, onConfirm, vipHall, isEditing } = this.props;

    const { isValid, validation } = this.validationSubmit({ selectedEmployeesIds, JsonData, PriceDetails, CompanyId, ProjectId, DepartmentId });

    if (isValid) {
      const Guid = guidService.generate();
      const stateToSave = JSON.parse(JSON.stringify(this.state));
      stateToSave.Guid = Guid;

      JsonData.EmployeeIds = selectedEmployeesIds;

      const flight = normalizeFlightConfirm(JsonData);

      JsonData.Flight = flight;

      this.normalizeDate();

      const item = {
        OrderTripItemId,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        EmployeesList: employeesListForPick.filter(({ Id }) => !!Id),

        Status,
        ServiceType,

        UserAnalytics,
        Guid,
        indexEdited: vipHall.indexEdited,

        JsonData: JSON.stringify(JsonData),
      };

      if (isEditing) {
        item.Description = Description;
      } else {
        item.PriceDetails = PriceDetails;
      }

      addTrip(stateToSave);
      onConfirm(item);
    } else {
      scrollToErrMessage();
      this.setState({
        validation: {
          ...validation,
        },
      });
    }
  };

  handleProviderFocus = async () => {
    const { tripService } = this.props;

    const result = await tripService.getAllProviders(SERVICE_NAME);

    this.setState({
      providersList: result,
    });
  };

  handleSelectProvider = (value) => {
    const { label } = value;
    const { tripService, isEditing } = this.props;

    if (isEditing && label === '') {
      return;
    }

    const result = tripService.vipHallTripFieldValidation(FIELDS.PROVIDERS, label);

    this.setState({
      Providers: value,
      JsonData: {
        ...this.state.JsonData,
        ProviderName: label,
      },
      validation: {
        ...this.state.validation,
        Providers: result,
      },
    });
  };

  handleSelectCompany = ({ id }) => {
    const { tripService, mandatoryProject } = this.props;
    const result = tripService.vipHallTripFieldValidation(FIELDS.COMPANYID, id);

    this.setState({
      CompanyId: id,
      Employees: {},
      mandatoryProject: isMandatory(mandatoryProject, id),
      validation: {
        ...this.state.validation,
        CompanyId: result,
        Employees: '',
      },
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;

    const result = mandatoryProject ? tripService.vipHallTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const {
      updatedServiceAnalytics,
      analyticsValidation,
    } = selectAnalytics(currentAnalytics, selectedTripAnalytics, selectedServiceAnalytics, analyticsValueId, setTripAnalytics);

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;

    const result = Departments.length ? tripService.vipHallTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  handleChangeVat = (field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      hasVat: value === 'true',
    },
  });

  validationSubmit = ({ JsonData, PriceDetails, CompanyId, ProjectId, DepartmentId }) => {
    const { tripService, tripAnalyticsValueIds: tripAnalytics, analytics, isEditing } = this.props;
    const { Departments, mandatoryProject, UserAnalytics: serviceAnalytics, Providers, selectedEmployeesIds, Description } = this.state;
    const { ProviderName } = JsonData;

    const employees = tripService.vipHallTripFieldValidation(FIELDS.EMPLOYEELIST, selectedEmployeesIds);
    const companyId = tripService.vipHallTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const projectId = mandatoryProject ? tripService.vipHallTripFieldValidation(FIELDS.PROJECTID, ProjectId) : '';
    const departmentId = Departments.length > 0 ? tripService.vipHallTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId) : '';

    const providers = !Providers.label && !ProviderName.length ? tripService.vipHallTripFieldValidation(FIELDS.PROVIDERS, Providers.label) : '';

    const description = isEditing ? tripService.customTripFieldValidation(FIELDS.DESCRIPTION, Description) : '';

    const price = tripService.vipHallTripFieldValidation(FUNDSFIELDS.BASE, PriceDetails.Base);
    const commissionPrice = !isEditing ? tripService.vipHallTripFieldValidation(FIELDS.COMMISSION, PriceDetails.commission) : '';
    const commissionPriceEdit = isEditing ? tripService.vipHallTripFieldValidation(PRICEFIELDSSHORT.COMMISSION, PriceDetails.Commission) : '';

    const userAnalytics = validateAnalytics(tripAnalytics, serviceAnalytics, analytics);
    const hasUnsetRequiredUserAnalytics = Object.keys(userAnalytics).some(key => !!userAnalytics[key]);

    const { isValid, validation } = validateSubmitFields(JsonData, tripService.vipHallTripFieldValidation);

    if (
      employees ||
      companyId ||
      projectId ||
      providers ||
      departmentId ||
      price ||
      commissionPrice ||
      commissionPriceEdit ||
      hasUnsetRequiredUserAnalytics ||
      description ||
      !isValid
    ) {
      return {
        isValid: false,
        validation: {
          ...validation,
          Employees: employees,
          Base: price,
          commission: commissionPrice,
          Commission: commissionPriceEdit,
          CompanyId: companyId,
          ProjectId: projectId,
          Providers: providers,
          DepartmentId: departmentId,
          MandatoryProject: mandatoryProject,
          Description: description,
          analytics: userAnalytics,
        },
      };
    }

    return {
      isValid: true,
    };
  };

  validationInput = (e, field) => {
    const result = this.props.tripService.vipHallTripFieldValidation(field, this.state.JsonData[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationSegments = (e, field, i) => {
    const { JsonData, validation } = this.state;

    const result = this.props.tripService.vipHallTripFieldValidation(field, JsonData.Flight[i][field]);

    const validationSegments = validation.Segments.map((item, index) => {
      if (index === i) {
        const segment = item;
        segment[field] = result;

        return segment;
      }

      return item;
    });

    this.setState({
      validation: {
        ...validation,
        Segments: validationSegments,
      },
    });
  }

  validationState = (e, field) => {
    const result = this.props.tripService.vipHallTripFieldValidation(field, this.state[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  }

  validationPrice = (e, field) => {
    const result = this.props.tripService.vipHallTripFieldValidation(field, this.state.PriceDetails[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  handleEmployeeFocus = async () => {
    const { tripService } = this.props;
    const { CompanyId } = this.state;

    await tripService.autocompleteEmployees(CompanyId, '');

    this.setState({
      validation: {
        ...this.state.validation,
        Employees: +CompanyId ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY,
      },
    });
  };

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  };

  handleChangePenaltyInput = (field, value, i) => this.setState((state) => {
    const newState = state;

    newState.JsonData.AdditionalServices.map((item, index) => {
      if (index === i) {
        const penalty = item;
        penalty[field] = value;

        return penalty;
      }

      return item;
    });

    return newState;
  });

  handleRemoveService = (i) => {
    const newAdditionalServices = [...this.state.JsonData.AdditionalServices];

    const changeItems = index => [
      ...newAdditionalServices.slice(0, index),
      ...newAdditionalServices.slice(index + 1),
    ];

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        AdditionalServices: changeItems(i),
      },
    });
  };

  handleAddAdditional = () => {
    const newAdditionalServices = [...this.state.JsonData.AdditionalServices];

    newAdditionalServices.push({ Name: '', Price: 0 });

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        AdditionalServices: newAdditionalServices,
      },
    });
  };

  renderError = (field) => {
    const { validation } = this.state;

    return (
      validation[field]
        ? <span className={ `error-msg ${style['error-block']}` }>{ validation[field] }</span>
        : null
    );
  };

  renderErrorSegments = (field, index) => {
    const { validation } = this.state;

    return (
      validation.Segments[index][field]
        ? <span className={ `error-msg ${style['error-block']}` }>{ validation.Segments[index][field] }</span>
        : null
    );
  }

  render() {
    const {
      PriceDetails,
      validation,
      employeesListForPick,
      employeesList,
      isEdit,
      Providers,
      providersList,
      CompanyId,
      UserAnalytics,
      ProjectId,
      Departments,
      DepartmentId,
      EmployeeId,
      Description,
      JsonData: {
        Name,
        ProviderOrderId,
        TripType,
        AdditionalInfo,
        AdditionalServices,
        CancellationDate,
        CancellationTime,
        Comment,
        PriceDetails: PriceDetailsJson,
        Flight,
        ProviderName,
      },
    } = this.state;
    const { companies, analytics, projects, tripAnalyticsValueIds, isEditing, mandatoryProject } = this.props;

    const labelButton = isEdit || isEditing ? LABELS.CONFIRM : LABELS.ADD_TRIP;

    return (
      <div className={ style.wrap }>
        <EditHistory
          isEditing={ isEditing }
          description={ Description }
          validation={ validation }
          onChangeDescription={ this.handleChangeState }
          validationHistory={ this.validationState }
        />
        <h4>{ LABELS.VOUCHER_INFO }</h4>
        <CountEmployeesFields
          employeesListForPick={ employeesListForPick }
          onIncreaseCountEmployees={ this.handleIncreaseCountEmployees }
          onDecreaseCountEmployees={ this.handleDecreaseCountEmployees }
        />
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <Input
              field={ FIELDS.NAME }
              value={ Name }
              label={ LABELS.NAME }
              onChange={ this.handleChangeInput }
              onBlur={ this.validationInput }
              valid={ validation.Name }
            />
          </div>
          <SelectCompany
            currentCompanyId={ CompanyId }
            companies={ companies }
            onSelect={ this.handleSelectCompany }
            validationCompany={ validation.CompanyId }
          />
        </div>
        <AnalyticsRows
          analytics={ analytics }
          serviceAnalyticsValueIds={ UserAnalytics }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          onSelect={ this.handleSelectAnalytics }
          validation={ validation.analytics }
        />
        <EmployeeFields
          employeesListForPick={ employeesListForPick }
          employeesList={ employeesList }
          companyId={ CompanyId }
          onRemoveEmployeeSuggest={ this.handleRemoveEmployeeSuggest }
          onSelectEmployee={ this.handleSelectEmployee }
          onEmployeeFocus={ this.handleEmployeeFocus }
          onChangeEmployee={ this.handleChangeEmployee }
          validation={ validation.Employees }
        />
        <div className={ style.row }>
          <SelectDepartment
            departments={ Departments }
            validationDepartment={ validation.DepartmentId }
            currentDepartmentId={ DepartmentId }
            currentEmployeeId={ EmployeeId }
            onSelect={ this.handleSelectDepartment }
          />
          <SelectProject
            disabled={ !CompanyId }
            currentProjectId={ ProjectId }
            validationProject={ validation.ProjectId }
            projects={ projects }
            mandatoryProject={ mandatoryProject }
            onSelect={ this.handleSelectProject }
          />
        </div>
        <ProviderFields
          providers={ Providers }
          validation={ validation }
          providersList={ providersList }
          providerName={ ProviderName }
          isEditing={ isEditing }
          onChangeProvider={ this.handleSelectProvider }
          providerOrderId={ ProviderOrderId }
          onChangeInput={ this.handleChangeInput }
          validationInput={ this.validationInput }
        />
        <TypesFields
          onChangeInput={ this.handleChangeInput }
          onChangeFlightType={ this.handleChangeFlightType }
          type={ Flight[0].Type }
          tripType={ TripType }
        />
        <AirportsFields
          onChangeInput={ this.handleChangeSegmentInput }
          onSelectSuggestAirport={ this.handleSelectSuggestAirport }
          onChangeSuggestAirport={ this.handleChangeSuggestAirport }
          renderErrorSegments={ this.renderErrorSegments }
          segments={ Flight }
          validation={ validation }
          validationSegments={ this.validationSegments }
        />
        <AdditionalFields
          additionalInfo={ AdditionalInfo }
          additionalServices={ AdditionalServices }
          onChangeInput={ this.handleChangeInput }
          onAddAdditional={ this.handleAddAdditional }
          onChangePenaltyInput={ this.handleChangePenaltyInput }
          onRemoveService={ this.handleRemoveService }
        />
        <div className={ `${style.row}  ${style.panel}` }>
          <div className={ style['col-1-3'] }>
            <Input
              field={ FIELDS.COMMENT }
              value={ Comment }
              label={ LABELS.COMMENT }
              onChange={ this.handleChangeInput }
            />
          </div>
        </div>
        <CancellationFields
          cancellationDate={ CancellationDate }
          cancellationTime={ CancellationTime }
          validation={ validation }
          onChangeInput={ this.handleChangeInput }
          validationInput={ this.validationInput }
          renderError={ this.renderError }
        />
        <PriceFields
          isEditing={ isEditing }
          priceDetails={ PriceDetails }
          validation={ validation }
          priceJson={ PriceDetailsJson }
          onChangePriceInput={ this.handleChangePriceInput }
          validationPrice={ this.validationPrice }
          onChangeVat={ this.handleChangeVat }
        />
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ labelButton }
            />
          </div>
        </div>
      </div>
    );
  }
}
