import React from 'react';
import PropTypes from 'prop-types';

import { VIP_HALL_TYPES } from '../../../../../../bi/constants/trips';

import { TypeFields } from './components/TypeFields';

import style from '../../../../styles/form.module.scss';

const TypesFields = ({
  onChangeInput,
  onChangeFlightType,
  type,
  tripType,
}) => (
  <div className={ `${style.row} ${style.panel}` }>
    <div className={ style['col-1-4'] }>
      <TypeFields
        onChangeInput={ onChangeFlightType }
        value={ type }
        type={ VIP_HALL_TYPES.FLIGHT_TYPE }
      />
    </div>
    <div className={ style['col-1-4'] }>
      <TypeFields
        onChangeInput={ onChangeInput }
        value={ tripType }
        type={ VIP_HALL_TYPES.TRIP_TYPE }
      />
    </div>
  </div>
);

TypesFields.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
  onChangeFlightType: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  tripType: PropTypes.string.isRequired,
};

export { TypesFields };
