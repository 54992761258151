import React from 'react';
import PropTypes from 'prop-types';

import { CancellationDatePicker } from './components/CancellationDatePicker';

import style from '../../../../styles/form.module.scss';

const CancellationFields = ({
  cancellationDate,
  cancellationTime,
  onChangeInput,
  validationInput,
  validation,
  renderError,
}) => (
  <div className={ `${style.row} ${style.panel} ${style.row_canceled}` }>
    <CancellationDatePicker
      cancellationDate={ cancellationDate }
      onChangeInput={ onChangeInput }
      validationInput={ validationInput }
      validation={ validation }
      renderError={ renderError }
    />
    <CancellationDatePicker
      cancellationTime={ cancellationTime }
      onChangeInput={ onChangeInput }
      validationInput={ validationInput }
      validation={ validation }
      renderError={ renderError }
      isTime
    />
  </div>
);

CancellationFields.propTypes = {
  cancellationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  cancellationTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  validationInput: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  renderError: PropTypes.func.isRequired,
};

export { CancellationFields };
