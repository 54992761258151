import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../components/input';
import SelectWithInput from '../../../../../../components/SelectWithInput';

import { getProviderOldName } from '../../../../../../bi/utils/trip';

import { FIELDS, LABELFIELDSRU } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';
import { TYPERATRATES } from '../../../../../../bi/constants/account';

import style from '../../../../styles/form.module.scss';

const LABELS = {
  PROVIDER_ORDER_ID: 'Номер бронирования у поставщика',
};

const ProviderFields = ({
  providers,
  validation,
  providersList,
  providerName,
  onChangeProvider,
  providerOrderId,
  onChangeInput,
  validationInput,
}) => {
  const providerOldName = getProviderOldName(providersList, providerName);

  const currentLabel = (providersList.length && providerOldName) ? providerOldName : providers;

  const providersListAndInput = [...providersList, { id: 0, type: TYPERATRATES.OPTIONAL, value: null, label: '' }];

  const renderProvider = () => (
    <SelectWithInput
      label={ LABELFIELDSRU.PROVIDERS }
      items={ providersListAndInput }
      currentItem={ currentLabel }
      onChange={ onChangeProvider }
      typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
      valid={ validation.Providers }
    />
  );

  return (
    <div className={ style.row }>
      <div className={ style['col-1-4'] }>
        <div className={ `${style.wrap} ${validation.OrderId ? style['no-valid-company'] : ''}` }>
          <Input
            field={ FIELDS.PROVIDER_ORDER_ID }
            value={ providerOrderId }
            label={ LABELS.PROVIDER_ORDER_ID }
            onChange={ onChangeInput }
            onBlur={ validationInput }
            valid={ validation.ProviderOrderId }
          />
        </div>
      </div>
      <div className={ style['col-1-4'] }>
        <div className={ `${style.wrap} ${validation.ProviderName ? style['no-valid-company'] : ''}` }>
          { renderProvider() }
        </div>
      </div>
    </div>
  );
};

ProviderFields.propTypes = {
  providers: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  providersList: PropTypes.array.isRequired,
  providerName: PropTypes.object.isRequired,
  onChangeProvider: PropTypes.func.isRequired,
  providerOrderId: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  validationInput: PropTypes.func.isRequired,
};

export { ProviderFields };
