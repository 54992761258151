import React from 'react';
import PropTypes from 'prop-types';

import Radio from '../../../../../../../../components/radio';

import { FIELDS, VAT_VALUE, LABELS_VAT } from '../../../../../../../../bi/constants/trips';

import style from '../../../../../../styles/form.module.scss';

const VatFields = ({ onChangeVat, priceDetails }) => (
  <div className={ `${style['col-1-4']} ${style['vat-action']}` }>
    <Radio
      label={ LABELS_VAT.WITH }
      field={ FIELDS.HASVAT }
      checked={ priceDetails.hasVat === VAT_VALUE.WITH }
      value={ VAT_VALUE.WITH }
      onChange={ (field, value) => onChangeVat(field, value) }
    />
    <Radio
      label={ LABELS_VAT.WITHOUT }
      field={ FIELDS.HASVAT }
      checked={ priceDetails.hasVat === VAT_VALUE.WITHOUT }
      value={ VAT_VALUE.WITHOUT }
      onChange={ (field, value) => onChangeVat(field, value) }
    />
  </div>
);

VatFields.propTypes = {
  onChangeVat: PropTypes.func.isRequired,
  priceDetails: PropTypes.object.isRequired,
};

export { VatFields };
